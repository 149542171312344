import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/global/layout/layout';

export const pageQuery = graphql`
  query ImpressumQuery {
    site {
      siteMetadata {
        titleSuffix
        titleDivider
      }
    }
  }
`

export default props => {
    return (
        <Layout wrapperClassName="bg-pattern-gold"
            title="Impressum"
            description="Rechtliche Informationen zum Betreiber der Webseite"
            titleSuffix={props.data.site.siteMetadata.titleSuffix}
            titleDivider={props.data.site.siteMetadata.titleDivider}>
            <section className="container py-5">
                <div className="row pt-5">
                    <div className="col-12 pt-5">
                        <h1 className="color-gold">Impressum</h1>
                        <address>
                            Schloss Westerburg GmbH &amp; Co. KG<br></br>
                            Schloss Westerburg<br></br>
                            56457 Westerburg im Westerwald
                        </address>
                        <p>Persönlich haftender Gesellschafter: MediCastle GmbH</p>

                        <h2 className="color-gold">Kontakt</h2>
                        <p>
                            Telefon: <a className="unstyled" href="tel:+492663911401">+49 2663 911 401</a><br></br>
                            Telefax: <a className="unstyled" href="tel:+492663911402">+49 2663 911 402</a><br></br>
                            E-Mail: <a className="unstyled" href="mailto:info@medicastle.de">info@medicastle.de</a><br></br>
                        </p>

                        <h2 className="color-gold">Registereintrag</h2>
                        <p>
                            Eintragung im Handelsregister<br></br>
                            Registergericht: Montabaur<br></br>
                            Registernummer: HRA 21680<br></br>
                        </p>

                        <h2 className="color-gold">Umsatzsteuer-ID</h2>
                        <p>
                            Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE299656946
                        </p>

                        <h2 className="color-gold">Haftungsausschluss</h2>
                        <p>
                            Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit
                            und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir
                            gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
                            Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte
                            fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                            hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                            Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                            Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen
                            werden wir diese Inhalte umgehend entfernen.
                        </p>
                        <p>
                            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
                            Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
                            Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
                            wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
                            Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
                            jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
                            werden wir derartige Links umgehend entfernen.
                        </p>

                        <h2 className="color-gold">Urheberrecht</h2>
                        <p>
                            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                            Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
                            Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                            Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
                            beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden
                            von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                        </p>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
